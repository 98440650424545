import React from 'react'
import './Footer.css';

const Footer = () => {
  return (
<div className="footer-distributed">

<div className="footer-left">

    <img src="/images/logos-06.png" alt="" />

  {/* <h3>Clinica<span>Cano</span></h3> */}

   <p className="footer-links">Expertos en Odontología Integral
    {/* <a href="#" class="link-1">Home</a>
    
    <a href="#">Blog</a>
  
    <a href="#">Pricing</a>
  
    <a href="#">About</a>
    
    <a href="#">Faq</a>
    
    <a href="#">Contact</a> */}
  </p> 

  <p className="footer-company-name">Cano Salud Dental © 1995</p>
</div>

<div className="footer-center">

  <div>
    <i className="fa fa-map-marker"></i>
    <p><span>San Antonio 486</span> Oficina 83, Santiago Centro</p>
  </div>

  <div>
    <i className="fa fa-phone"></i>
    <p>+569 7397 6822</p>
  </div>

  <div>
    <i className="fa fa-envelope"></i>
    <p><a href="mailto:support@company.com">contacto@clinicacano.com</a></p>
  </div>

</div>

<div className="footer-right">

  <p className="footer-company-about">
    <span>Siguenos en nuestras redes!</span>
    Quedemos en contacto. Suscribete a nuestras redes para más información y consejos de salud dental.
  </p>

  <div className="footer-icons">

    <a href="https://www.instagram.com/dra_andreacano/"><i class="fa fa-instagram"></i></a>
    <a href="#"><i class="fa fa-facebook"></i></a>
    <a href="https://wa.me/56988372434?text=Hola!%20Quiero%20reservar%20una%20hora%20para%20consulta"><i class="fa fa-whatsapp"></i></a>
    

  </div>

</div>
</div>





/* //  <div className="footer-container">
//     <div className="footer-wrapper">
//       <div className="footer-links-container">
//         <div className="footer-link-wrapper">
//         <div className="footer-link-items">
//           <div className="footer-link-title">Redes sociales</div>
//           <div to='/' className="footer-link">Instagram</div>
//           <div  to='/'className="footer-link">Instagram</div>
//           </div>
//         </div>

//         <div className="footer-link-items">
//           <div className="footer-link-title">Agenda con nosotros</div>
//           <div className="footer-link">+569 9999 777</div>
//           <div className="footer-link">correo@gmail.com</div>
//           </div>
//         </div>
        


//         </div>
//       </div>
    */


    
      







  )
}

export default Footer
