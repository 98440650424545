import React from 'react';
// import logo from './logo.svg';
import './App.css';
import NavBar from './components/NavBar';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';


function App() {
  return (
// Switch deprecado y se cambio por Routes
    <Router>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" />
    <a className='btn-wsp' href="https://wa.me/56957597752?text=Hola!%20Quiero%20reservar%20una%20hora%20para%20consulta."><i class="fa fa-whatsapp my-float"></i></a>
   <NavBar />
   <Routes>
    <Route path='/' exact 
      Component={Home}
    />

    </Routes> 
    </Router>
 


  );
}

export default App;
