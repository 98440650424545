import React from 'react'
import './FooterElements.css';
const FooterElements = () => {
  return (

   <div></div>
  )
}

export default FooterElements
