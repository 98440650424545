import React from 'react'
import './ServicesElements.css';

function ServicesElements() {
  return (

    <div className="services-container">
    <h2>Conoce las especialidades y servicios de nuestra clínica</h2>
    <div className="services-wrapper">
 
    <div className="services-card">
      <img src="/images/icon_1.png" alt="" className='img-servicios' />
        <h3>Cirugía Bucal</h3>
        
    </div>


    <div className="services-card">
      <img src="/images/icon_2.png" alt="" className='img-servicios' />
        <h3>Implantología</h3>
        <p>ㅤ</p>
    </div>


    <div className="services-card">
      <img src="/images/icon_3.png" alt="" className='img-servicios' />
        <h3>Odontología General</h3>
        <p>ㅤ</p>
    </div>
    <div className="services-card">
      <img src="/images/icon_4.png" alt="" className='img-servicios' />
        <h3>Ortodoncia</h3>
        <p> ㅤ</p>
    </div>

    </div>

    <div className="services-wrapper">
    <div className="services-card">
      <img src="/images/icon_5.png" alt="" className='img-servicios' />
        <h3>Endodoncia</h3>
        <p>ㅤ</p>
    </div>
    <div className="services-card">
      <img src="/images/icon_6.png" alt="" className='img-servicios' />
        <h3>Ortodoncia</h3>
        <p>ㅤ</p>
    </div>
    <div className="services-card">
      <img src="/images/icon_7.png" alt="" className='img-servicios' />
        <h3>Rehabilitación Oral</h3>
     
    </div>
    <div className="services-card">
      <img src="/images/icon_8.png" alt="" className='img-servicios' />
        <h3>Estética Dental</h3>
        <p>ㅤ</p>
    </div>

    </div>

    </div>




  )
}

export default ServicesElements
