import React from 'react'
import './Location.css';
import '../App.css';
import Button from './Button';

const Location = () => {
  return (
    <div className="location-container">
    <h2>Cómo llegar a Cano Salud Dental</h2>
    <div className="location-content-container">

    
    <img src="/images/entrada.jpg" alt="" />
  
        <img className='mapa' src="/images/mapa_ruta.png" alt="" />
  
        <p>Edificio Espacio Bahia en San Antonio 486 Oficina 83 Piso 8</p>
        

      
        <Button to='/' className='btns' buttonStyle='btn--outline' buttonSize='btn--large'>
          Reserva una cita  
        </Button>
    

    </div>



    </div>
  )
}

export default Location
