import React from 'react'
import './InfoSection.css';
import '../App.css';




const InfoSection = () => {
  return (
   <div className="info-container">
    
   <div className="info-content-container">
   

    <div className="info-item">
    <img src="/images/quality.png" alt="" />
      <h3>Calidad en servicios</h3>
      <p>Nuestros profesionales cuentan con 20 años de experiencia en salud dental</p>

    </div>

    <div className="info-item">
    <img src="/images/dentist.png" alt="" />
      <h3>Atencion personalizada</h3>
      <p>Tenemos los mejores profesionales disponibles para mejorar tu salud dental</p>
      

    </div>
    <div className="info-item">
    <img src="/images/train.png" alt="" />
      <h3>A pasos del metro</h3>
      <p>Estamos en el centro de Santiago pasos del Metro Plaza de Armas Línea 5  </p>

    </div>


   
   </div>
   </div>
  )
}

export default InfoSection
