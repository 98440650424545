import React from "react";
import './Button.css'
import { Link } from "react-router-dom";

const STYLES = ['btn--primary', 'btn--outline'];
const SIZES = ['btn--medium', 'btn--large'];

export const Button = ({ children, type, onClick, buttonStyle, buttonSize }) => {
    const checkButtonStyle = STYLES.includes(buttonStyle)
        ? buttonStyle
        : STYLES[0];
    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    return (
        <Link
            to='https://wa.me/56957597752?text=Hola!%20Quiero%20reservar%20una%20hora%20para%20consulta.'
            className={`btn ${checkButtonStyle} ${checkButtonSize}`}
            onClick={onClick}
            type={type}
        >
            {children}
        </Link>
    );
};

export default Button;



// import React from "react";
// import './Button.css'
// import { Link } from "react-router-dom";

//  const STYLES = ['btn--primary', 'btn--outline'];

//  const SIZES = ['btn--medium', 'btn--large'];

// export const Button = ({children, type, onClick, buttonStyle, buttonSize
// }) =>{
//     const checkButtonStyle = STYLES.includes(buttonStyle) 
//     ? buttonStyle 
//     : STYLES[0]
//     const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize: SIZES[0]
    
//     return(
//         <Link to='https://wa.me/56988372434?text=Hola!%20Quiero%20reservar%20una%20hora%20para%20consulta' className="btn-mobile">
//             <button className={`btn ${checkButtonStyle} ${checkButtonSize}`}
//             onClick={onClick}
//             type={type}
//             >  
//                 {children}
//             </button>
//         </Link>
//     )
// };

// export default Button