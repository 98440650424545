import React from 'react';
import '../../App.css'
import HeroSection from '../HeroSection'

import ServicesElements from '../ServicesElements';
import Footer from '../Footer';
import FooterElements from '../FooterElements';
import InfoSection from '../InfoSection';
import Location from '../Location';
import About from '../About';
import ServiceSection from '../ServiceSection';


function Home(){
    return(
        <>
        <HeroSection></HeroSection>
        <InfoSection></InfoSection>
        <ServicesElements></ServicesElements>
        <ServiceSection></ServiceSection>
        <About></About>
        <Location></Location>
        <Footer></Footer>
        <FooterElements></FooterElements>

        </>
    ) 
}
export default Home;