import React from 'react'
import './About.css';
import Button from './Button';

function About() {
  return (
    <div>
    <div className="about-container">
        <div className="about-content-container">


        <img src="/images/procedimiento.png" alt="" />
        
        <div className="about-text-container">
            <h3>Conócenos</h3>
            <p>Somos un equipo multidisciplinario de odontólogos  liderado por la Dra Cano quien coordina personalmente los casos más complejos y tiene más de 20 años de experiencia en especialidad de cirugía.</p>            
            <Button to='/' className='btns' buttonStyle='btn--outline' buttonSize='btn--large'>
          Reserva una cita  
        </Button>
        </div>
     

        </div>
    </div>


      
    </div>
  )
}

export default About
