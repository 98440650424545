import React from 'react'

function ServiceSection() {
  return (
    <div className="info-container">
    
    <div className="info-content-container">
    
 
     <div className="info-item">
     <img src="/images/clinic.png" alt="" />
       <h3>Todo en un sólo lugar</h3>
       <p>Contamos con todas las especialidades del área dental y nuestra evaluación es gratuita</p>
 
     </div>
 
     <div className="info-item">
     <img src="/images/cashless-payment.png" alt="" />
       <h3>Paga como más te acomode</h3>
       <p>Contamos con distintas formas de pago como Transbank, efectivo, transferencia</p>
       
 
     </div>
     <div className="info-item">
     <img src="images/tooth copy.png" alt="" />
       <h3>Convenios</h3>
       <p>Consulta por nuestros convenios y facilidades de pago </p>
 
     </div>
 
 
    
    </div>
    </div>
  )
}

export default ServiceSection
