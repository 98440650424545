import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import './navbar.css';
import Button from './Button';



function NavBar() {
  // Update the state. Inicialmente es falso
  const [click, setClick] = useState(false);
  const [button, setButton] =useState(true);

  // Hace lo contrario de la funcion click, con cada clcik va a cambiar su estado
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const showButton = () => {
    if (window.innerWidth <= 960){
      setButton(false);
    }else{
      setButton(true);
    }

  };
  useEffect(() =>{
    showButton()
  },[]);

  window.addEventListener('resize', showButton);

  return (
    <>
      
      <nav className='navbar'>
        <div className='navbar-container'>
        <div className="logo-container">
  
        <Link to="/" className='navbar-logo' onClick={closeMobileMenu}>
        <img src="/images/logo_cano_salud_dental.png" alt="" />
          {/* TRVL <i className="fab fa-typo3"></i>  */}
        </Link>
        </div>
        <div className='menu-icon' onClick={handleClick}>
        {/* Toggle */}
        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>

        <div className="links-container">

  
        {/* Para el el menu desaparezca */}
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>

        <li className='nav-item nav-links'> 
        <i className="fa fa-map-marker"></i>
        <p>San Antonio 486 Oficina 83, Santiago Centro</p>
        </li>
        <li className='nav-item nav-links'> 
        <i className="fa fa-phone"></i>
        <p >+569 5759 7752</p>
        </li>
       
      
        <li className='nav-item'> 
        <Link to='/' className='nav-links' onClick={closeMobileMenu}>
         Especialidades
        </Link>
        </li>
        <li className='nav-item'> 
        <Link to='/About' className='nav-links' onClick={closeMobileMenu}>
          Sobre Nosotros
        </Link>
        </li> 
        <li className='nav-item'> 
        <Link to='/' className='nav-links' onClick={closeMobileMenu}>
          ¿Cómo llegar?
        </Link>
        </li>
        

        </ul>
        </div>
       
  

        </div>
      </nav>
    </>
  )
}  

export default NavBar
 