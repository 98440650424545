import React from 'react'
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

function HeroSection() {

  const clases = 'btns'

  return (
    <div className='hero-container'>
    
    <div className="content-container">
    <div className="text-container">
    <p> Cano Salud Dental</p>
    <h1>Este es el momento para iluminar tu sonrisa</h1>
    <p>Una sonrisa no tiene comparación <br/>
      Agenda hoy tu cita con nosotros y recupérala</p>
      
      <div className='hero-btns'>
      <Button to='/' className='hero-btn'  buttonStyle='btn--outline' buttonSize='btn--large'>
          Reserva una cita  
        </Button>
      
      </div>
    </div>
  
  
    </div>
    </div>
  )
}

export default HeroSection;


